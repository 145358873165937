import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private subject = new Subject<boolean>();
  private observable = this.subject.asObservable();

  constructor() { }

  observe(): Observable<boolean> {
    return this.observable;
  }

  setLoading(value: boolean) {
    this.subject.next(value);
  }

}
